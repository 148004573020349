import React from 'react';

// Components
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import text2PayGray from '../../../../assets/images/textToPay-gray.png';
import email2PayGray from '../../../../assets/images/emailToPay-gray.png';
import payInFullGray from '../../../../assets/images/payInFull-gray.png';

// Helpers
import {
  setGADimensions,
  dropdownGAEventHandler,
  dropdownPayInFullGAEventHandler,
  dropdownTextToPayGAEventHandler,
} from '../Payment/googleAnalyticsHandlers';
import { Feature } from 'utils/helpers/featureFlag';

const PaymentDropdown = (props) => {
  const {
    setShowTextToPayModal,
    setShowEmailToPayModal,
    setShowPayInFullModal,
    paymentLinkEnabled,
    payInFullEnabled,
    practiceName,
    practiceId,
  } = props;

  // email to pay enabling config is linked to text to pay and
  const emailToPayEnabled = paymentLinkEnabled;

  setGADimensions(practiceName, practiceId);

  // props are needed since refs and styles are injected by overlay
  // show needs to be overwritten due to a bug that hasn't been fixed
  const renderTextToPayTooltip = (givenProps) => (
    <Tooltip
      className='tooltip-disabled'
      {...givenProps}
      show={givenProps.show.toString()}
    >
      Contact your Account Manager about getting started with Text to Pay
    </Tooltip>
  );

  const renderEmailToPayTooltip = (givenProps) => (
    <Tooltip
      className='tooltip-disabled'
      {...givenProps}
      show={givenProps.show.toString()}
    >
      Contact your Account Manager about getting started with Email to Pay
    </Tooltip>
  );

  const renderPayInFullTooltip = (givenProps) => (
    <Tooltip
      className='tooltip-disabled'
      {...givenProps}
      show={givenProps.show.toString()}
    >
      Contact your Account Manager about getting started with Pay in Full by
      Card
    </Tooltip>
  );

  const TextToPayDropdownItem = () => {
    return (
      <Dropdown.Item
        disabled={!paymentLinkEnabled}
        onClick={() => {
          dropdownTextToPayGAEventHandler();
          setShowTextToPayModal();
        }}
      >
        <img
          src={text2PayGray}
          style={{ width: '20px', marginRight: '5px' }}
          alt='text to pay icon in grayscale'
        />
        Text To Pay
        <br />
        <small>Send a text with a link for payment</small>
      </Dropdown.Item>
    );
  };

  const EmailToPayDropdownItem = () => {
    return (
      <Dropdown.Item
        disabled={!paymentLinkEnabled}
        onClick={() => {
          dropdownTextToPayGAEventHandler();
          setShowEmailToPayModal();
        }}
      >
        <img
          src={email2PayGray}
          style={{ width: '20px', marginRight: '5px' }}
          alt='email to pay icon in grayscale'
        />
        Email To Pay
        <br />
        <small>Send an email with a link for payment</small>
      </Dropdown.Item>
    );
  };

  const PayInFullDropdownItem = () => {
    return (
      <Dropdown.Item
        disabled={!payInFullEnabled}
        onClick={() => {
          dropdownPayInFullGAEventHandler();
          setShowPayInFullModal();
        }}
      >
        <img
          src={payInFullGray}
          style={{ width: '20px', marginRight: '5px' }}
          alt='pay by card icon in grayscale'
        />
        Pay by Card
        <br />
        <small>Enter card info for payment</small>
      </Dropdown.Item>
    );
  };

  return (
    <div className='p-4'>
      <div className='flex-wrap d-flex justify-content-end'>
        <Dropdown
          onClick={() => {
            dropdownGAEventHandler();
          }}
        >
          <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
            New Payment&nbsp;&nbsp;
          </Dropdown.Toggle>

          <Dropdown.Menu flip={true} align='right'>
            {paymentLinkEnabled ? (
              <TextToPayDropdownItem />
            ) : (
              <OverlayTrigger
                placement='left'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTextToPayTooltip}
              >
                {/* need this wrapper div for the overlay to work on a disabled element */}
                <span className='d-inline-block'>
                  <TextToPayDropdownItem />
                </span>
              </OverlayTrigger>
            )}
            <Feature name='Email to Pay'>
              {emailToPayEnabled ? (
                <EmailToPayDropdownItem />
              ) : (
                <OverlayTrigger
                  placement='left'
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderEmailToPayTooltip}
                >
                  {/* need this wrapper div for the overlay to work on a disabled element */}
                  <span className='d-inline-block'>
                    <EmailToPayDropdownItem />
                  </span>
                </OverlayTrigger>
              )}
            </Feature>
            {payInFullEnabled ? (
              <PayInFullDropdownItem />
            ) : (
              <OverlayTrigger
                placement='left'
                delay={{ show: 250, hide: 400 }}
                overlay={renderPayInFullTooltip}
              >
                {/* need this wrapper div for the overlay to work on a disabled element */}
                <span className='d-inline-block'>
                  <PayInFullDropdownItem />
                </span>
              </OverlayTrigger>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default PaymentDropdown;

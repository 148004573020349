// Modules
import React from 'react';
import { useHistory } from 'react-router-dom';

// Styles
import styles from './PracticeDataView.module.scss';

// Components
import { Button } from 'react-bootstrap';
import WorkingAreaTooltip from 'components/Common/WorkingAreaTooltip/WorkingAreaTooltip';
import IdShortener from 'utils/helpers/IdShortener';

import { isMobileRequirementField } from './practiceViewFunctions';

function getDisplayText(key, value) {
  switch (true) {
    case key === 'stripeId':
      return value ? value : 'unassigned';

    case key === 'authNetLogin':
      return value ? value : 'unassigned';

    case key === 'authNetPassword':
      return value ? value : 'unassigned';

    case key === 'payFabricSetupId':
    case key === 'payFabricDeviceId':
      return value ? value : 'unassigned';

    case key === 'installmentsMinAmount':
      return `$${value}`;
    case key === 'mobileSignatureCaptureMinimumAmount':
      return value ? `$${value}` : '$50'; // defined in VitusPay

    case key === 'merchantFeePercentage':
      return value ? `${value}%` : 'unassigned';

    case key === 'installmentFeePercentage':
      return value ? `${value}%` : 'unassigned';

    case key === 'paymentLinkPercentage':
      return value ? `${value}%` : 'unassigned';

    case key === 'paymentPlatform':
      // 'Not Set' should not occur... make sure the dynamoUpdatePaymentPlatform script is run in backend
      return value ?? 'Not Yet Set';

    case key === 'payWithSavedCards':
    case key === 'paymentLinkCustomMessage':
    case isMobileRequirementField(key):
      return value ? 'YES' : 'NO';

    case key === 'paymentLinkEnabled' && value === undefined:
      return 'NO';

    case typeof value === 'boolean':
      return value ? 'YES' : 'NO';

    case /\d,\d/.test(value):
      return value.replace(/,/g, ', ');

    default:
      return value;
  }
}

function DisplayFields(props) {
  const { registeredFields } = props;
  const entries = Object.entries(registeredFields);

  return entries.map((entry) => {
    const key = entry[0];
    const fieldObj = entry[1];
    // The PracticeView component calls the updateRegisteredFields function
    // which adds the "val" property used here
    const { val, name, header, inDevelopment } = fieldObj;

    return (
      <div key={`${key}_${val}`} className={styles[name]}>
        <small className={styles.fieldHeader}>
          {header}
          {inDevelopment ? (
            <span
              className='ml-1 vitus-primary-blue'
              style={{ fontSize: '11px' }}
            >
              <WorkingAreaTooltip />
            </span>
          ) : null}
        </small>
        <p>{getDisplayText(key, val)}</p>
      </div>
    );
  });
}

function getChevronClass(showMoreDetails) {
  return `${styles.chevron} fas ${
    showMoreDetails ? 'fa-chevron-up' : 'fa-chevron-down'
  }`;
}

function getMoreDetailsContainerClass(showMoreDetails) {
  return `${styles.moreDetails} ${
    showMoreDetails ? styles.expand : styles.collapse
  }`;
}

/**
 * ==================
 * Exported component
 * ==================
 */

function PracticeDataView(props) {
  const {
    practice,
    basicDetailsWithVals,
    toggleEditMode,
    paymentOptionsRegisteredFields,
    paxDeviceRegisteredFields,
    showMoreDetails,
    toggleMoreDetails,
  } = props;

  const history = useHistory();

  const handleViewUsers = () => {
    history.push(`/app/practices/${practice.id}/users`);
  };

  const handleViewTransactions = () => {
    history.push(`/app/practices/${practice.id}/transactions`);
  };

  return (
    <div>
      <div className={styles.basicDetailsGrid}>
        {basicDetailsWithVals.map((entry) => {
          return entry[0] === 'id' ? (
            <IdShortener
              key={entry[1]}
              id={entry[1]}
              styles={{
                fontSize: '14px',
                fontFamily: "'Montserrat-Medium', sans-serif",
              }}
            />
          ) : (
            <p className='mb-0' key={entry[0]}>
              {entry[1]}
            </p>
          );
        })}
        <i
          onClick={toggleMoreDetails}
          className={getChevronClass(showMoreDetails)}
        ></i>
      </div>

      <div className={getMoreDetailsContainerClass(showMoreDetails)}>
        {/* Payment Options Section */}
        <hr className={`${showMoreDetails ? styles.hr : 'd-none'}`} />
        <p
          className='mb-4'
          style={{
            fontSize: '14px',
            fontFamily: "'Montserrat-Medium', sans-serif",
          }}
        >
          Payment Options
        </p>
        <div className={styles.paymentOptionsGridContainer}>
          <DisplayFields registeredFields={paymentOptionsRegisteredFields} />
        </div>

        {/* Pax Device Settings Section */}
        <hr className={`${showMoreDetails ? styles.hr : 'd-none'}`} />
        <p
          className='mb-4'
          style={{
            fontSize: '14px',
            fontFamily: "'Montserrat-Medium', sans-serif",
          }}
        >
          Pax Device Settings
        </p>
        <div className={styles.paxDeviceGridContainer}>
          <DisplayFields registeredFields={paxDeviceRegisteredFields} />
        </div>

        <div className={styles.buttonContainer}>
          <Button
            variant='outline-secondary'
            className='mr-2'
            onClick={handleViewUsers}
          >
            VIEW USERS
          </Button>
          {/* account for Paybright practices too */}
          <Button
            variant='outline-secondary'
            className='mr-2'
            onClick={handleViewTransactions}
          >
            VIEW TRANSACTIONS
          </Button>

          <Button onClick={toggleEditMode}>EDIT INFO</Button>
        </div>
      </div>
    </div>
  );
}

export default PracticeDataView;
